<template>
  <section class="applicant-modal-reject">
    <applicant-rejected-reason
      @input="handleReasonInput"
    />
    <applicant-rejected-feedback
      :applicant="applicant"
      :show-template-feedback="showFeedbackTemplate"
      @input="handleFeedbackInput"
    />
    <section class="applicant-modal-reject__actions">
      <ev-button
        class="applicant-modal-reject__button"
        data-test="button-cancel"
        color="blue"
        variant="secondary"
        @click="handleCancel"
      >
        {{ $t(`${langPath}.cancel`) }}
      </ev-button>
      <ev-button
        class="applicant-modal-reject__button"
        data-ga-id="confirmed_reject_applicant_on_next_tasks_module"
        data-test="button-reject"
        :disabled="!rejectionReason"
        @click="handleReject"
      >
        {{ $t(`${langPath}.reject`) }}
      </ev-button>
    </section>
  </section>
</template>

<script>
import ApplicantRejectedReason from '@/pages/ApplicantRejected/components/ApplicantRejectedReason'
import ApplicantRejectedFeedback from '@/pages/ApplicantRejected/components/ApplicantRejectedFeedback'
import { APPLICANTS, NEXT_TASKS } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'
import EvButton from '@revelotech/everestV2/EvButton'
import { TheNotificationCardList } from '@revelotech/everest'
import applicants from '@/repository/applicants'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)
const nextTasksHelper = createNamespacedHelpers(NEXT_TASKS)

export default {
  name: 'ApplicantModalReject',
  components: {
    ApplicantRejectedReason,
    ApplicantRejectedFeedback,
    EvButton
  },
  data () {
    return {
      langPath: __langpath,
      rejectionReason: '',
      rejectionMessage: ''
    }
  },
  computed: {
    ...applicantsHelper.mapState(['applicant']),
    ...nextTasksHelper.mapState(['activeTab']),
    showFeedbackTemplate () { return !!this.rejectionReason }
  },
  methods: {
    ...applicantsHelper.mapActions([
      'removeApplicantWithUnscheduledInterviews',
      'removeApplicantWithConductedInterviews',
      'removeApplicantWithSentProposals'
    ]),
    handleReasonInput (value) { this.rejectionReason = value },
    handleFeedbackInput (value) { this.rejectionMessage = value },
    removeCandidate () {
      switch (this.activeTab) {
        case 'unscheduledInterview':
          this.removeApplicantWithUnscheduledInterviews(this.applicant?.id)
          break
        case 'conductedInterview':
          this.removeApplicantWithConductedInterviews(this.applicant?.id)
          break
        case 'sentProposal':
          this.removeApplicantWithSentProposals(this.applicant?.id)
      }
    },
    async handleReject () {
      await applicants.rejectCandidate({
        applicantId: this.$route.params.applicantId,
        rejectionReason: this.rejectionReason,
        rejectionMessage: this.rejectionMessage || null
      }).then(() => {
        this.removeCandidate()
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.successMessage`),
          modifier: 'success'
        })
        this.$router.push({ name: 'NewHome' })
      }).catch(() => {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.errorMessage`),
          modifier: 'error'
        })
      })
    },
    handleCancel () { this.$router.back() }
  }
}
</script>

<style lang="scss">
.applicant-modal-reject {
  &__button {
    margin-left: $base*4;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: $base*4;
    padding-top: $base*4;
  }
}
</style>
